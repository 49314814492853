import React from "react"
import {
  btn,
  primary,
  btn__text,
  btn_icon,
  btn_icon_mobile,
  secondary,
  tertiary,
  btn_mobile,
} from "../styles/button.module.css"

const AppButton = ({
  title, // text inside button
  onClick, // on click method
  icon, // material icon
  size = "small", // small/large
  variant = "primary", // primary/secondary/tertiary
  ...otherProps
}) => {
  return (
    <button
      onClick={onClick}
      className={`${btn} ${size === "mobile" && btn_mobile} ${
        variant === "primary"
          ? primary
          : variant === "secondary"
          ? secondary
          : tertiary
      }`}
      {...otherProps}
    >
      {icon && (
        <span
          className={`material-icons ${
            size === "mobile" ? btn_icon_mobile : btn_icon
          }`}
        >
          {icon}
        </span>
      )}
      <span
        className={`${btn__text} ${
          size === "small"
            ? "sub-heading-s medium"
            : size === "large"
            ? "sub-heading medium"
            : "paragraph2 light"
        }`}
      >
        {title}
      </span>
    </button>
  )
}

export default AppButton
