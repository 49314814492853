import React from 'react';
import { CouncilCard } from './CouncilCard';
import developerData from '../data/developers';
import { developer } from '../styles/developer.module.css';

const Developer = ({ setOpen }) =>
{
    return (
        <div className={`fixed rounded-3xl shadow-md z-50 mx-auto ${developer} flex flex-col items-center justify-center`}
            style={{
                top: 0,
                width: '100vw',
                height: '100vh',
                transform: 'translateX(50%-750px)',
                overflowY: "scroll"
            }}
        >
            <div className='bg-white w-11/12 max-w-7xl mx-auto px-4'
                style={{
                    height: '700px',
                    overflowY: 'auto',
                    backgroundColor: "white"
                }}
            >
                <div className='flex items-center py-5 justify-between'>
                    <span></span>
                    <button className='font-2xl' onClick={() => setOpen(false)} >
                        <span className='material-icons'>close</span>
                    </button>
                </div>
                {/* <div className='flex flex-col gap-4'>
                    <h1 className='text-4xl font-bold text-center'>Developers</h1>
                    <p className='text-center text-lg'>Meet the developers who have contributed to the development of this website</p>
                </div> */}
                <div className={`pb-5 grid md:grid-cols-2 xl:grid-cols-3 gap-4 bg-white`}>
                    {developerData.map((member, i) => (
                        <CouncilCard details={member} key={i} dev={true} />
                    )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Developer;