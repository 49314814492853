import swal from "sweetalert"

export const errorAlert = ({ title, subtitle }) => {
  return swal({
    title,
    text: subtitle,
    icon: "error",
    button: "Ok",
    dangerMode: true,
  })
}
