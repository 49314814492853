import { StaticImage } from "gatsby-plugin-image"
import React, { useContext, useState } from "react"
import API from "../../apis/index"
import AuthContext from "../../routes/context"
import {
  login,
  login_box,
  login_forgot,
  login_sec_text,
  login_text_ter,
} from "../../styles/login.module.css"
import { errorAlert } from "../Alert"
import AppButton from "../AppButton"
import AppFormField from "../AppFormField"
import GoogleAuth from "../GoogleAuth"

const Login = ({ setOpen }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState({
    email: null,
    password: null,
  })
  const isBrowser = typeof window !== "undefined"

  const { authState, setAuthState } = useContext(AuthContext)

  const onSubmit = async () => {
    if (email === "") {
      setError({ ...error, email: "Email is required" })
    } else if (password === "") {
      setError({ ...error, password: "Password is required" })
    } else {
      setError({ email: null, password: null })
      try {
        const res = await API.login({ email, password })
        if (res.data.user) {
          API.setAuthToken(res.data.token)
          const payload = {
            type: "normal",
            token: res.data.token,
            user: res.data.user,
          }
          if (isBrowser) {
            localStorage.setItem("auth", JSON.stringify(payload))
          }
          setAuthState({
            ...authState,
            isAuthenticated: true,
            user: res.data.user,
            authType: "normal",
          })
          setOpen()
        }
      } catch (err) {
        console.log(err.message)
        if (isBrowser) {
          localStorage.removeItem("auth")
        }
        setAuthState({
          ...authState,
          isAuthenticated: false,
          user: null,
          authType: "",
        })
        if (err.response && err.response.status === 404) {
          setOpen(false)
          errorAlert({
            title: "Invalid credentials",
            subtitle: "Please enter valid credentials",
          })
        }
      }
    }
  }

  const handleGoogleAuth = async data => {
    console.log(data)
    if (!data.error) {
      const emailId = data.profileObj.email
      const username = data.profileObj.givenName
      const pass = emailId + "_Pasc"

      try {
        let res = await API.signup({
          email: emailId,
          password: pass,
          name: username,
        })
        if (res.data.user) {
          API.setAuthToken(res.data.token)
          if (isBrowser) {
            localStorage.setItem(
              "auth",
              JSON.stringify({
                user: res.data.user,
                token: res.data.token,
                google_token: data.tokenId,
              })
            )
          }
          setAuthState({
            ...authState,
            isAuthenticated: true,
            user: res.data.user,
            authType: "google",
          })
          setOpen(false)
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          try {
            const res = await API.login({ email: emailId, password: pass })
            API.setAuthToken(res.data.token)
            if (isBrowser) {
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  user: res.data.user,
                  token: res.data.token,
                  google_token: data.tokenId,
                })
              )
            }
            setAuthState({
              ...authState,
              isAuthenticated: true,
              user: res.data.user,
              authType: "google",
            })
            setOpen(false)
          } catch (error) {
            console.log(error.message)
            errorAlert({
              title: "Error",
              subtitle: "Unable to login. Please try again later",
            })
          }
        } else {
          console.log(error.message)
          errorAlert({
            title: "Error",
            subtitle: "Unable to login. Please try again later",
          })
        }
      }
    } else {
      console.log(data.error)
      errorAlert({
        title: "Error",
        subtitle: data.error,
      })
    }
  }

  return (
    <div className="fixed top-0 flex items-center justify-center z-50 min-h-screen w-screen">
      <div
        className={`bg-white shadow sm:rounded-lg flex justify-center relative`}
      >
        <button
          className="cursor-pointer absolute top-5 right-5 z-50"
          onClick={() => setOpen()}
        >
          <span className="material-icons text-4xl text-black lg:text-white">
            close
          </span>
        </button>
        <div className={`lg:w-1/3 xl:w-5/12 sm:m-9 xs:p-4 ${login_box}`}>
          <div className="flex flex-col xs:items-center">
            <h1 className={`${login_text_ter}`}>Log in</h1>

            <AppFormField
              value={email}
              error={error.email}
              label="Please enter a valid email"
              placeholder="Email"
              type="text"
              onChange={e => setEmail(e.target.value)}
            />
            <AppFormField
              value={password}
              error={error.password}
              label="Password"
              placeholder="Minimum 6 character"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />

            <a className={`${login_forgot}`} href="https://www.google.com">
              Forgot Password
            </a>
            <br></br>
            <AppButton
              title="Log In"
              style={{ width: "103px" }}
              onClick={onSubmit}
            />
            <br />
            <span className={`${login_text_ter}`}>Or login with</span>
            <div>
              {/* <a href="" target="_blank" key="Google" rel="noreferrer">
              <img src="https://img.icons8.com/color/48/000000/google-logo.png" />
            </a> */}
              <GoogleAuth
                Component={props => (
                  <img
                    target="_blank"
                    src="https://img.icons8.com/color/48/000000/google-logo.png"
                    style={{ cursor: "pointer" }}
                    alt="pasc"
                    {...props}
                  />
                )}
                onSuccess={handleGoogleAuth}
                onFailure={handleGoogleAuth}
              />
            </div>
          </div>
        </div>
        <div
          className={`${login} relative right-0 bg-var(--acmb_900) text-center hidden lg:flex`}
        >
          <StaticImage
            src="../images/Vector.svg"
            className="absolute top-0 right-0 md:block"
          />
          <p className={`${login_sec_text} w-full`}>
            Don't have an account?
            <br />
            Make one{" "}
            <button
              style={{ color: "var(--acc_500)" }}
              onClick={() => setOpen("signup")}
            >
              here.
            </button>
          </p>
          <StaticImage
            src="../images/Vector.svg"
            className="absolute bottom-0 transform rotate-180 right-0 md:block"
          />
        </div>
      </div>
    </div>
  )
}

export default Login
