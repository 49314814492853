import React from "react"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="overflow-hidden">{children}</div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
