import React from "react"
import {
  councilCard,
  councilCardImage,
  councilCardName,
  councilCardDesignation,
  // councilCardIntro,
  councilCardSocials,
  councilCardVector,
  councilCardSocialsContainer,
} from "../styles/ourteam.module.css"
import githubsvg from "../images/socials/github.svg"
import linkedinsvg from "../images/socials/linkedin.svg"
import instagramsvg from "../images/socials/instagram.svg"
export const CouncilCard = props => {
  console.log(props)
  const { name, surname, imageUrl, designation, socials } = props.details
  return (
    <>
      <div className={`${councilCard}`} style={{
        height: props?.dev ? "400px" : "430px",
      }}>
        <img
          src={imageUrl}
          alt="councilCard"
          className={`${councilCardImage}`}
        />
        <div className={`${councilCardSocialsContainer}`}>
          {socials.github ? (
            <a href={socials.github} target="_blank" rel="noreferrer">
              <div className={`${councilCardSocials}`}>
                <img
                  src={githubsvg}
                  className={`${councilCardVector}`}
                  alt="github-svg"
                />
              </div>
            </a>
          ) : null}

          {socials.linkedin ? (
            <a href={socials.linkedin} rel="noreferrer" target="_blank">
              <div className={`${councilCardSocials}`}>
                <img
                  src={linkedinsvg}
                  className={`${councilCardVector}`}
                  alt="linkedin-svg"
                />
              </div>
            </a>
          ) : null}
          {socials.instagram ? (
            <a href={socials.instagram} target="_blank" rel="noreferrer">
              <div className={`${councilCardSocials}`}>
                <img
                  src={instagramsvg}
                  className={`${councilCardVector}`}
                  alt="insta-svg"
                />
              </div>
            </a>
          ) : null}
        </div>

        <div className={`${councilCardName}`}>{`${name} ${surname}`}</div>
        <div className={`${councilCardDesignation}`}>{designation}</div>
        {/* <div className={`${councilCardIntro}`}>{intro}</div> */}
      </div>
    </>
  )
}
