import design from "../../static/Team/2021/design.png"

const developerData = [
    {
        name: "Abhishek",
        surname: "Kushwaha",
        intro: "Lorem ipsum",
        imageUrl: design,
        // designation: "Design Head",
        socials: {
          linkedin: "https://www.linkedin.com/in/kushwaha-abhishek/",
        },
        year: "2021",
    },
    {
        name: "Vimal",
        surname: "Galani",
        intro: "Lorem ipsum1",
        imageUrl:
          "https://i.imgur.com/gctrKTy.jpg",
        // designation: "Technical Head",
        socials: {
          github: "https://github.com/vimalgalani28",
          linkedin: "https://www.linkedin.com/in/vimal-galani-a7b977199/",
          instagram: "https://www.instagram.com/vimal_galani/",
        },
        year: "2022",
    },
    {
        name: "Ashutosh",
        surname: "Shaha",
        intro: "Lorem ipsum1",
        imageUrl:
          "https://i.imgur.com/6Zr4VTa.jpg",
        // designation: "Chairperson",
        socials: {
          github: "https://github.com/ashutosh264/",
          linkedin: "https://www.linkedin.com/in/ashutosh-shaha/",
          instagram: "https://www.instagram.com/ashutosh_264/",
        },
        year: "2022",
      },
]

export default developerData;
