import { StaticImage } from "gatsby-plugin-image"
import React, { useContext, useState } from "react"
import API from "../../apis"
import AuthContext from "../../routes/context"
import {
  login,
  login_box,
  login_forgot,
  login_sec_text,
  login_text_ter,
} from "../../styles/login.module.css"
import { errorAlert } from "../Alert"
import AppButton from "../AppButton"
import AppFormField from "../AppFormField"
import GoogleAuth from "../GoogleAuth"

const Signup = ({ setOpen }) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const isBrowser = typeof window !== "undefined"

  const [error, setError] = useState({
    email: "",
    password: "",
    name: "",
    confirmPass: "",
  })

  const { authState, setAuthState } = useContext(AuthContext)

  const onSubmit = async () => {
    const obj = { name, email, password, confirmPass }
    let errorObj = {}
    let anyError = false
    Object.keys(error).forEach(err => {
      if (obj[err] === "") {
        anyError = true
        errorObj[err] = `${err} is required`
      } else errorObj[err] = ""
    })
    setError({ ...errorObj })

    if (!anyError) {
      try {
        if (confirmPass === password) {
          const res = await API.signup({ email, password, name })
          if (res.data.user) {
            API.setAuthToken(res.data.token)
            setAuthState({
              ...authState,
              user: res.data.user,
              isAuthenticated: true,
              authType: "normal",
            })
            if (isBrowser) {
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  user: res.data.user,
                  token: res.data.token,
                  google_token: null,
                })
              )
            }
            setOpen(false)
          } else console.log(res.data)
        } else {
          errorAlert({
            title: "Unable to signup",
            subtitle: "Passwords do not match. Please check password",
          })
        }
      } catch (error) {
        console.log(error.message)
        if (error.response && error.response.status === 404) {
          setOpen(false)
          errorAlert({
            title: "Invalid credentials",
            subtitle: "Please enter valid credentials",
          })
        } else {
          errorAlert({
            title: "Error",
            subtitle: error.message,
          })
        }
      }
    }
  }

  const handleGoogleAuth = async data => {
    if (!data.error) {
      const emailId = data.profileObj.email
      const username = data.profileObj.givenName
      const pass = emailId + "_Pasc"

      try {
        let res = await API.signup({
          email: emailId,
          password: pass,
          name: username,
        })
        if (res.data.user) {
          API.setAuthToken(res.data.token)
          if (isBrowser) {
            localStorage.setItem(
              "auth",
              JSON.stringify({
                user: res.data.user,
                token: res.data.token,
                google_token: data.tokenId,
              })
            )
          }
          setAuthState({
            ...authState,
            isAuthenticated: true,
            user: res.data.user,
            authType: "google",
          })
          setOpen(false)
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          try {
            const res = await API.login({ email: emailId, password: pass })
            API.setAuthToken(res.data.token)
            if (isBrowser) {
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  user: res.data.user,
                  token: res.data.token,
                  google_token: data.tokenId,
                })
              )
            }
            setAuthState({
              ...authState,
              isAuthenticated: true,
              user: res.data.user,
              authType: "google",
            })
            setOpen(false)
          } catch (error) {
            console.log(error.message)
            errorAlert({
              title: "Error",
              subtitle: "Unable to signup. Please try again later",
            })
          }
        } else {
          console.log(error.message)
          errorAlert({
            title: "Error",
            subtitle: "Unable to signup. Please try again later",
          })
        }
      }
    } else {
      console.log(data.error)
      errorAlert({
        title: "Error",
        subtitle: data.error,
      })
    }
  }

  return (
    <div className="fixed top-0 flex items-center justify-center z-50 min-h-screen w-screen">
      <div
        className={`bg-white shadow sm:rounded-lg flex justify-center relative`}
      >
        <button
          className="cursor-pointer absolute top-5 right-5 z-50"
          onClick={() => setOpen()}
        >
          <span className="material-icons text-4xl text-black">close</span>
        </button>
        <div
          className={`${login} relative right-0 bg-var(--acmb_900) text-center hidden lg:flex`}
        >
          <StaticImage
            src="../images/Vector.svg"
            className="absolute top-0 right-0 md:block"
          />
          <p className={`${login_sec_text}  w-full`}>
            Already registered?
            <br />
            Log in{" "}
            <button
              style={{ color: "var(--acc_500)" }}
              onClick={() => setOpen("login")}
            >
              here.
            </button>
          </p>
          <StaticImage
            src="../images/Vector.svg"
            className="absolute bottom-0 transform rotate-180 right-0 md:block"
          />
        </div>
        <div className={`lg:w-1/3 xl:w-5/12 sm:m-9 xs:p-4 ${login_box}`}>
          <div className="flex flex-col zx:items-center">
            <h1 className={`${login_text_ter}`}>Register</h1>
            <AppFormField
              value={name}
              label="Name"
              placeholder="Write your full name"
              type="text"
              error={error.name}
              onChange={e => setName(e.target.value)}
            />
            <AppFormField
              value={email}
              label="Please enter a valid email"
              placeholder="Email"
              type="text"
              error={error.email}
              onChange={e => setEmail(e.target.value)}
            />
            <AppFormField
              value={password}
              error={error.password}
              label="Password"
              placeholder="Minimum 6 character"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <AppFormField
              value={confirmPass}
              error={
                error.confirmPass !== "" ? "Please rewrite password" : null
              }
              label="Confirm Password"
              placeholder="Please type the password again"
              type="password"
              onChange={e => setConfirmPass(e.target.value)}
            />
            <a className={`${login_forgot}`} href="https://www.google.com">
              Forgot Password
            </a>
            <br></br>
            <AppButton
              title="Sign Up"
              style={{ width: "103px" }}
              onClick={onSubmit}
            />
            <br />
            <span className={`${login_text_ter}`}>Or sign up with</span>
            <div>
              {/* <a href="" target="_blank" key="Google" rel="noreferrer">
              <img src="https://img.icons8.com/color/48/000000/google-logo.png" />
            </a> */}
              <GoogleAuth
                Component={props => (
                  <img
                    target="_blank"
                    src="https://img.icons8.com/color/48/000000/google-logo.png"
                    style={{ cursor: "pointer" }}
                    alt="pasc"
                    {...props}
                  />
                )}
                onSuccess={handleGoogleAuth}
                onFailure={handleGoogleAuth}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
