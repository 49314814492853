// extracted by mini-css-extract-plugin
export var headerContainer = "ourteam-module--headerContainer---Fzdn";
export var councilCard = "ourteam-module--councilCard--53M6q";
export var councilCardImage = "ourteam-module--councilCardImage--VY357";
export var councilCardName = "ourteam-module--councilCardName--5pPWK";
export var councilCardDesignation = "ourteam-module--councilCardDesignation--mJPrW";
export var councilCardIntro = "ourteam-module--councilCardIntro--ZsEW8";
export var councilCardSocials = "ourteam-module--councilCardSocials--Sln11";
export var councilCardVector = "ourteam-module--councilCardVector--DVK1K";
export var councilCardSocialsContainer = "ourteam-module--councilCardSocialsContainer--P0Ows";
export var gridContainer = "ourteam-module--gridContainer--tvXWX";
export var dropDown = "ourteam-module--dropDown--Tqkxz";